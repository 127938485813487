<template>
  <div class="about">
    <section>
      <div class="wrap">
        <div
          class="banner"
          style="background-image: url('/assets/home/2-less-cost.png')"
        ></div>

        <div class="content">
          <h1>Privacy Policy</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vel
            vehicula ipsum, non tempor sem. Nulla lobortis ex ut faucibus
            sagittis. Phasellus blandit nunc in ipsum accumsan, et fringilla
            augue pulvinar. Aliquam imperdiet justo purus, in suscipit nisl
            interdum ut. Pellentesque feugiat lorem sed dapibus porta.
            Suspendisse potenti. Nulla volutpat est id lorem efficitur blandit.
            Suspendisse potenti. Curabitur elementum nec magna at volutpat. Sed
            consequat gravida orci, eget malesuada mi porta at. Curabitur nisl
            dolor, varius eget enim eget, pharetra cursus magna. Praesent
            convallis eget neque nec fermentum. Nulla condimentum quam et
            gravida mattis.
          </p>
          <p>
            Vivamus ut auctor enim. Maecenas convallis diam justo, id vehicula
            ante feugiat eu. Pellentesque semper augue sem, a viverra mi
            vestibulum nec. Phasellus a lobortis arcu, et tempor nibh. Proin
            malesuada in dui a condimentum. Nulla rutrum cursus est, ultricies
            tristique odio tincidunt et. Sed et lobortis mi, interdum pulvinar
            est. Donec nec libero eu nunc elementum sodales. Curabitur convallis
            consectetur aliquet. Etiam vehicula massa vitae sollicitudin
            bibendum. Suspendisse nisi sapien, elementum sit amet nisl sed,
            tincidunt hendrerit nisi. Nam interdum sit amet lacus nec mollis.
            Mauris condimentum, arcu quis maximus aliquam, arcu velit dignissim
            enim, et commodo libero augue sit amet augue. Duis malesuada urna
            sed justo iaculis venenatis. Nulla sit amet interdum nisi. Sed
            facilisis faucibus gravida.
          </p>
          <p>
            Nunc aliquet est a ipsum lacinia, vitae facilisis quam semper. Fusce
            leo tellus, mattis at aliquet vitae, volutpat vitae nisi. Curabitur
            consectetur neque elit, quis mattis sem porttitor a. Ut quis mattis
            lacus, a posuere quam. Fusce tempor ultricies orci ac gravida. Donec
            mauris quam, imperdiet at eleifend eget, rutrum sed diam. In feugiat
            imperdiet nulla et blandit. Maecenas fermentum suscipit dapibus.
          </p>
        </div>
      </div>
      <site-footer></site-footer>
    </section>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import SiteFooter from '@/components/SiteFooter'
export default defineComponent({
  name: 'PrivacyPolicy',
  components: { SiteFooter },
})
</script>
<style lang="scss" scoped>
section {
  background-color: white;

  .wrap {
    .content {
      @apply py-40;
      h1 {
        @apply text-2xl text-primary font-bold mb-8;
      }
      p {
        @apply text-black;
      }
    }

    .headings1 {
      height:35px
    }
  }
}
</style>
